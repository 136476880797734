// extracted by mini-css-extract-plugin
export var ArtistDescription = "NielsShoeMeulman-module--ArtistDescription--3iEWy";
export var ArtistInfos = "NielsShoeMeulman-module--ArtistInfos--m86fy";
export var ButtonWrapper = "NielsShoeMeulman-module--ButtonWrapper --Ze3dG";
export var CardWrapper = "NielsShoeMeulman-module--CardWrapper--EjZee";
export var CarrouselWrapper2 = "NielsShoeMeulman-module--CarrouselWrapper2--xmQ82";
export var Citations = "NielsShoeMeulman-module--Citations--jXTQO";
export var DescriptionWrapper = "NielsShoeMeulman-module--DescriptionWrapper--jmRil";
export var ImageWrapper = "NielsShoeMeulman-module--ImageWrapper--xxJQl";
export var LinkWrapper = "NielsShoeMeulman-module--LinkWrapper--b75Q3";
export var MobileProtrait = "NielsShoeMeulman-module--MobileProtrait--dIit1";
export var More = "NielsShoeMeulman-module--More--nkdhS";
export var MoreButton = "NielsShoeMeulman-module--MoreButton--hV+3M";
export var NameWrapper = "NielsShoeMeulman-module--NameWrapper--b63DI";
export var PdpWrapper = "NielsShoeMeulman-module--PdpWrapper--h9ymK";
export var PhotosWrapper = "NielsShoeMeulman-module--PhotosWrapper--zMOFT";
export var ProfilWrapper = "NielsShoeMeulman-module--ProfilWrapper--uqPSQ";
export var TitleWrapper = "NielsShoeMeulman-module--TitleWrapper--8uWrK";
export var Wrapper = "NielsShoeMeulman-module--Wrapper--aObTH";